import React from "react"
import { Box, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material"
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from "dayjs"

export default function KoeInfo( {newSample, setNewSample}) {
   
    function handleChange(event) {
        setNewSample(prevState => {
            return {
                ...prevState,
                [event.target.name]: event.target.value
            }
        })
    }
    let wrongKoeId = false
    if(newSample.moederId.includes(',')){wrongKoeId = true}
    if(newSample.moederId.includes('.')){wrongKoeId = true}
    if(newSample.moederId.includes('-')){wrongKoeId = true}

    return (
        <>
            <TextField
                error={wrongKoeId}
                id="moederId"
                label="koenummer"
                name="moederId"
                variant="standard"
                value={newSample.moederId}
                size="normal"
                type="number"
                margin="normal"
                inputProps={{ inputMode: 'numeric', }}
                fullWidth
                onChange={handleChange}
                InputLabelProps={{ shrink: true, }}
                helperText={wrongKoeId && "De ingevoerde waarde is niet geldig. Controleer of de waarde geen speciale tekens bevat, zoals een punt (.), een spatie (,) of een negatief teken (-)."}
            />
            <br></br>
            <br></br>
            
            <Typography sx={{color:'gray'}} variant='caption'>leeftijd van de koe</Typography>
            
            <ToggleButtonGroup exclusive fullWidth color="primary" id='moederLeeftijd' name='moederLeeftijd' value={newSample.moederLeeftijd} onChange={handleChange}>
                <ToggleButton size="small" name='moederLeeftijd' value='vaars'>vaars</ToggleButton>
                <ToggleButton size="small" name='moederLeeftijd' value='2e kalf'>2e kalf</ToggleButton>
                <ToggleButton size="small"name='moederLeeftijd' value='ouder'>ouder</ToggleButton>
            </ToggleButtonGroup>

            <br></br>
            <br></br>

            <Box display="flex" justifyContent="space-between" >
                <DatePicker
                    id='moederDroogstand'
                    name='moederDroogstand'
                    value={dayjs(newSample.moederDroogstand)}
                    format='DD-MM-YYYY'
                    label='droogstandsdatum'
                    onChange={(newValue) => setNewSample((prevState) => ({ ...prevState, moederDroogstand: newValue }))}
                    slotProps={{
                        textField: {
                            variant: 'standard',
                        },
                    }}
                    disableFuture
                />
            </Box>
        </>
    )
}