import { API } from "aws-amplify"
import { listBiests } from "../graphql/queries"

export default async function fetchBiestListFarmer(setData, farmer){
    const apiData = await API.graphql({ 
        query: listBiests,
        variables:{ 
            limit:1000,
            filter: {
                boerEmail: {
                    eq: farmer
                }
            }
        }
    })
    const biestFromAPI = apiData.data.listBiests.items;
    setData(biestFromAPI)
}
