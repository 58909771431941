import React, { useState } from "react"
import { Fab, Box } from "@mui/material"
import { Add as AddIcon } from '@mui/icons-material'

import AddSampleDialog from "./addSampleComponents/AddSampleDialog"

export default function AddSample({ setBiestList, selectedFarmer}) {

    const [open, setOpen] = useState(false)

    return (
        <>
            <Box display='flex' justifyContent='center' > 
                <Fab
                    onClick={() => setOpen(true)}
                    sx={{ position: 'fixed', top: window.innerHeight/2-24 }}
                    variant="extended"
                    size="large"
                    color="primary"
                    aria-label="add"
                >
                    <AddIcon sx={{ mr: 1 }}/>
                    Registreer Biest
                </Fab>
            </Box>
            <AddSampleDialog open={open} setOpen={setOpen} setBiestList={setBiestList} selectedFarmer = {selectedFarmer} />
        </>
    )
}