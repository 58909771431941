import React, { useState } from "react"
import { Box } from "@mui/material"

import Biest from "./mainComponents/Biest"
import Koe from "./mainComponents/Koe"
import Kalf from "./mainComponents/Kalf"
import Bedrijf from "./mainComponents/Bedrijf"
import Manager from "./mainComponents/Manager"
import Navigator from "./mainComponents/Navigator"

export default function Main({ biestList, setBiestList, selectedFarmer}) {

  const [screenSelect, setScreenSelect] = useState(0)

  return (
    <Box style={{ position: 'fixed', width: '100%' }}>
      {screenSelect === 0 && <Biest biestList={biestList} setBiestList={setBiestList} selectedFarmer={selectedFarmer} />}
      {screenSelect === 1 && <Koe biestList={biestList} setBiestList={setBiestList} selectedFarmer={selectedFarmer} />}
      {screenSelect === 2 && <Kalf biestList={biestList} selectedFarmer={selectedFarmer} />}
      {screenSelect === 3 && <Bedrijf biestList={biestList} selectedFarmer={selectedFarmer} />}
      {screenSelect === 4 && <Manager biestList={biestList} selectedFarmer={selectedFarmer} />}
      <Navigator screenSelect={screenSelect} setScreenSelect={setScreenSelect} />
    </Box>
  )
}