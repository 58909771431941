import { API } from "aws-amplify"
import { createBiest as createBiestMutation } from "../graphql/mutations"
import fetchBiestListFarmer from "./fetchBiestListFarmer"

export default async function createBiest(setData, biest, farmer){
    await API.graphql({
        query: createBiestMutation,
        variables: { input: biest },
    });
    fetchBiestListFarmer(setData, farmer)
}