import React, { useState, useEffect } from "react"
import { Amplify } from "aws-amplify"
import "@aws-amplify/ui-react/styles.css"
import { withAuthenticator } from "@aws-amplify/ui-react"
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import Header from "./components/Header"
import Main from "./components/Main"
import fetchBiestListFarmer from "./functions/fetchBiestListFarmer"

const App = ({ signOut }) => {

  const [biestList, setBiestList] = useState([])
  const [selectedFarmer, setSelectedFarmer] = useState(Amplify.Auth.user.attributes.email)

  useEffect(() => {
    fetchBiestListFarmer(setBiestList,selectedFarmer)
  }, [selectedFarmer])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Header signOut={signOut} biestList={biestList} setBiestList={setBiestList} selectedFarmer={selectedFarmer} setSelectedFarmer = {setSelectedFarmer}/>
      <Main biestList={biestList} setBiestList={setBiestList} selectedFarmer={selectedFarmer}/>
    </LocalizationProvider>
  );
};

export default withAuthenticator(App, { hideSignUp: true });